import React from "react"
import PropTypes from "prop-types"
import ActionDialog from "highline/components/action_dialog"
import ButtonGroup from "highline/components/button_group"
import Button from "highline/components/button"
import InputWithLabel from "highline/components/input_with_label"
import { getGeneralError, getInputError } from "highline/utils/form_errors"

const CreateCategoryModal = ({
  error,
  newCategoryName,
  newCategorySlug,
  onInputChange,
  onModalCloseClicked,
  onSubmit,
}) => {

  return (
    <ActionDialog
      error={ getGeneralError(error) }
      title="Create Category"
      onClose={ onModalCloseClicked }
      isForm
      onSubmit={ onSubmit }
    >
      <InputWithLabel
        error={ getInputError(error, "category", "name") }
        label="Category Name"
        name="newCategoryName"
        type="text"
        value={ newCategoryName }
        required
        sensitive
        onChange={ (e) => onInputChange("newCategoryName", e.target.value) }
      />
      <InputWithLabel
        error={ getInputError(error, "category", "slug") }
        label="URL Key"
        name="newCategorySlug"
        type="text"
        value={ newCategorySlug }
        required
        sensitive
        onChange={ (e) => onInputChange("newCategorySlug", e.target.value) }
      />
      <ButtonGroup>
        <Button
          layout="primary"
          type="submit"
        >
          Submit
        </Button>
      </ButtonGroup>
    </ActionDialog>
  )
}

CreateCategoryModal.propTypes = {
  error: PropTypes.string,
  newCategoryName: PropTypes.string,
  newCategorySlug: PropTypes.string,
  onInputChange: PropTypes.func,
  onModalCloseClicked: PropTypes.func,
  onSubmit: PropTypes.func,
}

CreateCategoryModal.defaultProps = {
  isLoading: false,
  onInputChange: () => {},
  onModalCloseClicked: () => {},
}

export default CreateCategoryModal
