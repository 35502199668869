import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import ResourceTree from "highline/components/admin/resource_tree"
import CreateCategoryModal from "highline/components/admin/categories/create_category_modal"
import CategoryEditMenu from "highline/components/admin/category_edit_menu"
import Button from "highline/components/button"

import styles from "highline/styles/components/admin/categories/index.module.css"

class AdminCategoriesIndex extends React.Component {
  static propTypes = {
    categoryList: ImmutablePropTypes.list,
    createCategoryError: PropTypes.string,
    error: ImmutablePropTypes.map,
    isCreateModalVisible: PropTypes.bool,
    loadCategories: PropTypes.func,
    newCategoryName: PropTypes.string,
    newCategorySlug: PropTypes.string,
    onChangeTreeData: PropTypes.func,
    onClickAddChild: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickManagePrograms: PropTypes.func,
    onInputChange: PropTypes.func,
    onModalCloseClicked: PropTypes.func,
    onMoveNode: PropTypes.func,
    onSubmitCreateCategory: PropTypes.func,
    onVisibilityToggled: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      menuId: null,
    }
  }

  componentDidMount() {
    this.props.loadCategories()
  }

  onClickMoreOptions = (node) => {
    this.setState({ menuId: node.id })
  }

  handleClick = (evt) => {
    evt.stopPropagation()
    this.setState({ menuId: null })
  }

  handleClose = () => {
    this.setState({ menuId: null })
  }

  handleKeyDown = (evt) => {
    if (evt.keyCode == 27) {
      evt.stopPropagation()
      this.handleClose()
    }
  }

  render() {
    const {
      categoryList,
      error,
      isCreateModalVisible,
      newCategoryName,
      newCategorySlug,
      onChangeTreeData,
      onClickAddChild,
      onClickEdit,
      onClickDelete,
      onClickManagePrograms,
      onInputChange,
      onModalCloseClicked,
      onMoveNode,
      onSubmitCreateCategory,
      onVisibilityToggled,
    } = this.props

    const {
      menuId,
    } = this.state

    return (
      <div>
        <div
          className={ styles.container }
          onClick={ this.handleClick }
          onKeyDown={ this.handleKeyDown }
        >
          <ResourceTree
            currentPopUpId={ menuId }
            onChange={ onChangeTreeData }
            onClickMoreOptions={ this.onClickMoreOptions }
            onVisibilityToggled={ onVisibilityToggled }
            onMoveNode={ onMoveNode }
            tooltipMenuComponent={
              <CategoryEditMenu
                onClickAddChild={ onClickAddChild }
                onClickEdit={ onClickEdit }
                onClickDelete={ onClickDelete }
                onClickManagePrograms={ onClickManagePrograms }
              />
            }
            treeData={ categoryList }
          />

          <Button
            align="inline"
            onClick={ onClickAddChild }
            className={ styles.createRootCategoryButton }
          >
            Create Root Category
          </Button>
        </div>

        { isCreateModalVisible &&
          <CreateCategoryModal
            error={ error }
            newCategoryName={ newCategoryName }
            onInputChange={ onInputChange }
            onModalCloseClicked={ onModalCloseClicked }
            newCategorySlug={ newCategorySlug }
            onSubmit={ (evt) => {
              evt.preventDefault()
              onSubmitCreateCategory(this.state.menuId)
            } }
          />
        }
      </div>
    )
  }
}

export default AdminCategoriesIndex
