
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/admin-new/categories",
      function () {
        return require("private-next-pages/admin-new/categories/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/admin-new/categories"])
      });
    }
  