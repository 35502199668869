import React from "react"
import PropTypes from "prop-types"
import AdminEditMenu from "highline/components/admin/admin_edit_menu"
import AdminEditMenuItem from "highline/components/admin/admin_edit_menu_item"
import { EditIcon, PlusIcon, ShirtIcon, TrashIcon } from "highline/components/icons"

const CategoryEditMenu = ({
  node,
  onClickAddChild,
  onClickDelete,
  onClickEdit,
  onClickManagePrograms,
}) => (
  <AdminEditMenu>
    <AdminEditMenuItem
      icon={ <ShirtIcon /> }
      label="Manage Programs"
      onClick={ () => onClickManagePrograms(node.id) }
    />
    <AdminEditMenuItem
      icon={ <PlusIcon /> }
      label="Add a child"
      onClick={ () => onClickAddChild(node.id) }
    />
    <AdminEditMenuItem
      icon={ <EditIcon /> }
      label="Edit"
      onClick={ () => onClickEdit(node.id) }
    />
    <AdminEditMenuItem
      icon={ <TrashIcon /> }
      label="Delete"
      onClick={ () => (
        confirm("Are you sure you want to delete?") ? onClickDelete(node.id) : null 
      ) }
    />
  </AdminEditMenu>
)

CategoryEditMenu.propTypes = {
  node: PropTypes.object,
  onClickAddChild: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickManagePrograms: PropTypes.func,
}

export default CategoryEditMenu
