import Router from "next/router"
import { connect } from "react-redux"
import AdminCategoriesIndex from "highline/components/admin/categories/index"

import {
  addChildClicked,
  categoryVisibilityToggled,
  createCategoryInputChanged,
  createCategoryAsync,
  deleteCategoryAsync,
  fetchAllCategoriesAsync,
  modalCloseClicked,
  onChangeTreeData,
  updateCategoryPositionAsync,
} from "highline/redux/admin/actions/categories_actions"

const mapStateToProps = (state) => {
  return {
    authenticationToken: state.getIn(["auth", "authenticationToken"]),
    categoryId: state.getIn(["categories", "index", "categoryId"]),
    categoryList: state.getIn(["categories", "index", "list"]),
    error: state.getIn(["categories", "index", "error"]),
    isCreateModalVisible: state.getIn(["categories", "index", "isCreateModalVisible"]),
    newCategoryName: state.getIn(["categories", "index", "newCategoryName"]),
    newCategorySlug: state.getIn(["categories", "index", "newCategorySlug"]),
  }
}

// Find the target node's position in parent list
const findNewPosition = (categoryId, list) => {
  const node = list.find((node) => node.id == categoryId)
  return list.indexOf(node)
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCategories: () => {
      dispatch(fetchAllCategoriesAsync())
    },
    onChangeTreeData: (list) => {
      dispatch(onChangeTreeData(list))
    },
    onVisibilityToggled: ({ node, expanded }) => {
      dispatch(categoryVisibilityToggled(node, expanded))
    },
    onMoveNode: ({ node, nextParentNode, treeData: rootCategories }) => {
      const categoryId = node.id
      let siblingNodes = nextParentNode && nextParentNode.children
      siblingNodes = siblingNodes || rootCategories
      const newPosition = findNewPosition(categoryId, siblingNodes)
      dispatch(updateCategoryPositionAsync(categoryId, newPosition))
    },
    onClickEdit: (categoryId) => {
      Router.push(
        "/admin-new/categories/[id]",
        `/admin-new/categories/${categoryId}`,
      )
    },
    onClickManagePrograms: (categoryId) => {
      Router.push(
        "/admin-new/categories/[id]/manage-programs",
        `/admin-new/categories/${categoryId}/manage-programs`,
      )
    },
    onClickAddChild: () => {
      dispatch(addChildClicked())
    },
    onClickDelete: (categoryId) => {
      dispatch(deleteCategoryAsync(categoryId))
    },
    onInputChange: (name, value) => {
      dispatch(createCategoryInputChanged(name, value))
    },
    onModalCloseClicked: () => {
      dispatch(modalCloseClicked())
    },
    onSubmitCreateCategory: (categoryId) => {
      dispatch(createCategoryAsync(categoryId))
    },
  }
}

const AdminCategoriesIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCategoriesIndex)

export default AdminCategoriesIndexContainer
